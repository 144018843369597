<template>
    <Modal v-model="showForm" title="编辑盘点单" :mask-closable="false" width="850px">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <FormItem label="盘点单名称" prop="title">
                <Input type="text" show-word-limit :maxlength="50" v-model.trim="editForm.title" placeholder="请输入"/>
            </FormItem>
            <FormItem label="开始日期" prop="startTime">
                <DatePicker type="date" v-model="editForm.startTime" placeholder="请选择" clearable :editable="false"></DatePicker>
            </FormItem>
            <FormItem label="结束日期" prop="endTime">
                <DatePicker type="date" v-model="editForm.endTime" placeholder="请选择" clearable :editable="false"></DatePicker>
            </FormItem>
            <FormItem label="盘点人" prop="checkerName">
                <Select v-model="editForm.checkerName" multiple placeholder="请选择" filterable>
                    <Option v-for="item in checkerList" :value="item.id" :key="item.id">{{ item.name }}
                    </Option>
                </Select>
            </FormItem>
            <FormItem label="备注" prop="memo">
                <Input type="textarea" show-word-limit :maxlength="200" v-model.trim="editForm.memo"
                       :autosize="{minRows: 3,maxRows: 5}" placeholder="请输入"/>
            </FormItem>
        </Form>
        <div class="filter" style="margin-top: 24px">
            <Form label-colon inline>
                <FormItem>
                    <Button type="primary" @click="showEditDialog" icon="md-add">编辑资产</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList">
                </Table>
                <editAsset v-model="editAssetShow" ref="msg" :inventoryId="inventoryId" :assetIdList="assetIdList" :assetIdList1="assetIdList1" @getAssetIds="getAssetIds"></editAsset>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize"
                              :total="total"
                              :current="pageNum"
                              :page-size-opts="[5,10,15,20]"
                              @on-change="changePage"
                              @on-page-size-change="changPageSize"
                              show-sizer
                              show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import { reqGetAssetList, reqGetInfoByInventoryId, reqGetAssetByInventoryId, reqGetAssetByIds, reqEditInventory } from '../../../../api/asset-api';
import { reqGetUserList } from '../../../../api/system-api';
import editAsset from '../inventory/editAsset';

export default {
    name: "editInventory",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        inventoryId: ''
    },
    components: {
        editAsset,
    },
    data() {
        let checkStartDate = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请选择开始日期'));
            } else {
                callback();
            }
        };
        let checkEndDate = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请选择结束日期'));
            } else {
                if(value < new Date(new Date().setDate(new Date().getDate()-1))){
                    return callback(new Error("结束日期不能小于当前日期"));
                }
                callback();
            }
        };
        return {
            showForm: this.value,
            loading: false,
            editForm: {
                title: '',
                startTime: '',
                endTime: '',
                checkerName: [],
                memo: '',
                assetList: [],
            },
            dataList: [],
            checkerList: [],
            editAssetShow: false,
            assetArray: [],
            total: 0,
            pages: 1,
            pageNum: 1,
            pageSize: 5,
            assetIdList: '', // 最开始编辑时的资产id集合
            assetIdList1: '', // 改变资产后的资产id集合
            oldTotal: '',
            editFormRule: {
                title: [
                    {required: true, message: '请输入盘点单名称', trigger: 'blur'},
                ],
                startTime: [
                    {required: true, type: 'date', validator: checkStartDate, trigger: 'change'},
                ],
                endTime: [
                    {required: true, type: 'date', validator: checkEndDate, trigger: 'change'},
                ],
                checkerName: [
                    {required: true, message: '请选择盘点人', trigger: 'change', type: 'array'},
                ]
            },
            columns: [
                {
                    title: '资产编码',
                    key: 'encoding',
                    tooltip: 'ture',
                    width: 170
                },
                {
                    title: '资产名称',
                    key: 'name',
                    tooltip: 'ture',
                    minWidth: 120
                },
                {
                    title: '资产分类',
                    key: 'classifyName',
                    tooltip: 'ture',
                    minWidth: 120
                },
                {
                    title: '资产位置',
                    key: 'locationName',
                    tooltip: 'ture',
                    minWidth: 120
                },
                {
                    title: '品牌',
                    key: 'brand',
                    tooltip: 'ture',
                    width: 100
                },
                {
                    title: '型号',
                    key: 'model',
                    tooltip: 'ture',
                    width: 100
                },
            ]
        }
    },
    methods: {
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            if(this.total === this.oldTotal){
                this.getAssetByInventoryId();
            } else {
                this.getAssetList();
            }
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            if(this.total === this.oldTotal){
                this.getAssetByInventoryId();
            } else {
                this.getAssetList();
            }
        },
        getCheckerList(){
            reqGetUserList().then((res) => {
                this.checkerList = res.data;
            }).catch(() => {
                this.checkerList = [];
            });
        },
        getAssetIds(ids){
            this.assetArray = ids;
            this.getAssetList();
            if(ids === ''){
                this.editForm.assetList = [];
            } else {
                this.editForm.assetList = this.assetArray.split(',');
            }
            this.assetIdList1 = ids;
            this.assetIdList = ids;
        },
        showEditDialog: function () {
            this.editAssetShow = true;
        },
        getAssetList(){
            let that = this;
            let para = {
                pageNum: that.pageNum,
                pageSize: that.pageSize,
                ids: that.assetArray,
            };
            reqGetAssetByIds(para).then((res) => {
                that.total = res.data.data.total;
                that.pages = res.data.data.pages;
                that.dataList = res.data.data.records;
            }).catch(() => {
                this.dataList = [];
            });
        },
        handleSubmit() {
            if(this.editForm.assetList.length === 0){
                this.$Message.error("请添加需要盘点的资产");
                return;
            }
            if(this.editForm.startTime > this.editForm.endTime){
                this.$Message.error("开始日期不能大于结束日期");
                return;
            }
            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    reqEditInventory(this.editForm).then(res => {
                        if (res.data.code == 1) {
                            this.loading = false;
                            this.$Message.success(res.data.msg);
                            // 提交表单数据成功则关闭当前的modal框
                            this.closeModal(false);
                            // 同时调用父页面的刷新页面的方法
                            this.$emit('getInventoryPage');
                        } else {
                            this.loading = false;
                            this.$Message.error(res.data.msg);
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.$Message.error("编辑失败");
                    });
                }
            });
        },
        closeModal(val) {
            this.$emit('input', val);
            this.$refs.msg.clearTempArr();
        },
        getAssetByInventoryId(){
            let that = this;
            let para = {
                pageNum: that.pageNum,
                pageSize: that.pageSize,
                inventoryId: this.inventoryId
            };
            reqGetAssetByInventoryId(para).then((res) => {
                that.total = res.data.data.total;
                that.pages = res.data.data.pages;
                that.dataList = res.data.data.records;
                this.oldTotal = res.data.data.total;
            }).catch(() => {
                that.dataList = [];
            });
        },
        getInfoByInventoryId(){
            reqGetInfoByInventoryId({id: this.inventoryId}).then(res => {
                this.editForm = res.data.data[0];
                this.editForm.checkerName = res.data.data[0].checker.split(',');
                this.assetIdList = res.data.data[0].assetList.map(item => item.assetId).join(',');
                this.editForm.assetList = res.data.data[0].assetList.map(item => item.assetId);
            });
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.getInfoByInventoryId();
                this.getAssetByInventoryId();
                this.getCheckerList();
                this.dataList = [];
                this.total = 0;
                this.pages = 1;
                this.pageNum = 1;
                this.pageSize = 5;
                this.$refs.msg.clearTempArr();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
