<template>
    <Modal v-model="showForm" title="新增盘点单" :mask-closable="false" width="850">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="90">
            <FormItem label="盘点单名称" prop="title">
                <Input type="text" show-word-limit :maxlength="50" v-model.trim="addForm.title" placeholder="请输入"/>
            </FormItem>
            <FormItem label="开始日期" prop="startDate">
                <DatePicker type="date" v-model="addForm.startDate" placeholder="请选择" clearable :editable="false"></DatePicker>
            </FormItem>
            <FormItem label="结束日期" prop="endDate">
                <DatePicker type="date" v-model="addForm.endDate" placeholder="请选择" clearable :editable="false"></DatePicker>
            </FormItem>
            <FormItem label="盘点人" prop="checkerIds">
                <Select v-model="addForm.checkerIds" multiple placeholder="请选择" filterable>
                    <Option v-for="item in checkerList" :value="item.id" :key="item.id">{{ item.name }}
                    </Option>
                </Select>
            </FormItem>
            <FormItem label="备注" prop="memo">
                <Input type="textarea" show-word-limit :maxlength="200" v-model.trim="addForm.memo"
                       :autosize="{minRows: 3,maxRows: 5}" placeholder="请输入"/>
            </FormItem>
        </Form>
        <div class="filter" style="margin-top: 24px">
            <Form label-colon inline>
                <FormItem>
                    <Button type="primary" @click="showAddDialog" icon="md-add">选择资产</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList">
                </Table>
                <addAsset v-model="addAssetShow" ref="msg" @getAssetIds="getAssetIds"></addAsset>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize"
                              :total="total"
                              :current="pageNum"
                              :page-size-opts="[5,10,15,20]"
                              @on-change="changePage"
                              @on-page-size-change="changPageSize"
                              show-sizer
                              show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import { reqGetAssetList, reqUser, reqAddInventory, reqGetAssetByIds } from '../../../../api/asset-api';
import { reqGetUserList } from '../../../../api/system-api';
import addAsset from '../inventory/addAsset';

export default {
    name: "addInventory",
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    components: {
        addAsset,
    },
    data() {
        let checkStartDate = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请选择开始日期'));
            } else {
                callback();
            }
        };
        let checkEndDate = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请选择结束日期'));
            } else {
                if(value < new Date(new Date().setDate(new Date().getDate()-1))){
                    return callback(new Error("结束日期不能小于当前日期"));
                }
                callback();
            }
        };
        return {
            showForm: this.value,
            loading: false,
            addForm: {
                title: '',
                startDate: '',
                endDate: '',
                checkerIds: [],
                memo: '',
                assetList: [],
            },
            dataList: [],
            keywords: '',
            checkerList: [],
            assetArray: [],
            addAssetShow: false,
            total: 0,
            pages: 1,
            pageNum: 1,
            pageSize: 5,
            addFormRule: {
                title: [
                    {required: true, message: '请输入盘点单名称', trigger: 'blur'},
                ],
                startDate: [
                    {required: true, type: 'date', validator: checkStartDate, trigger: 'change'},
                ],
                endDate: [
                    {required: true, type: 'date', validator: checkEndDate, trigger: 'change'},
                ],
                checkerIds: [
                    {required: true, message: '请选择盘点人', trigger: 'change', type: 'array'},
                ]
            },
            columns: [
                {
                    title: '资产编码',
                    key: 'encoding',
                    tooltip: 'ture',
                    width: 170
                },
                {
                    title: '资产名称',
                    key: 'name',
                    tooltip: 'ture',
                    minWidth: 120
                },
                {
                    title: '资产分类',
                    key: 'classifyName',
                    tooltip: 'ture',
                    minWidth: 120
                },
                {
                    title: '资产位置',
                    key: 'locationName',
                    tooltip: 'ture',
                    minWidth: 120
                },
                {
                    title: '品牌',
                    key: 'brand',
                    tooltip: 'ture',
                    width: 100
                },
                {
                    title: '型号',
                    key: 'model',
                    tooltip: 'ture',
                    width: 100
                },
            ]
        }
    },
    methods: {
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getAssetList();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getAssetList();
        },
        getCheckerList(){
            reqGetUserList().then((res) => {
                this.checkerList = res.data;
            }).catch(() => {
                this.checkerList = [];
            });
        },
        getAssetList(){
            let that = this;
            let para = {
                pageNum: that.pageNum,
                pageSize: that.pageSize,
                ids: that.assetArray,
            };
            reqGetAssetByIds(para).then((res) => {
                that.total = res.data.data.total;
                that.pages = res.data.data.pages;
                that.dataList = res.data.data.records;
            }).catch(() => {
                this.dataList = [];
            });
        },
        getAssetIds(ids){
            this.assetArray = ids;
            this.getAssetList();
            this.addForm.assetList = this.assetArray.split(',');
        },
        showAddDialog: function () {
            this.addAssetShow = true;
        },
        handleSubmit() {
            if(this.dataList.length === 0){
                this.$Message.error("请添加需要盘点的资产");
                return;
            }
            if(this.addForm.startDate > this.addForm.endDate){
                this.$Message.error("开始日期不能大于结束日期");
                return;
            }
            this.$refs['addForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    reqAddInventory(this.addForm).then(res => {
                        if (res.data.code == 1) {
                            this.loading = false;
                            this.$Message.success(res.data.msg);
                            // 提交表单数据成功则关闭当前的modal框
                            this.closeModal(false);
                            // 同时调用父页面的刷新页面的方法
                            this.$emit('getInventoryPage');
                            // 清空添加资产的已选项
                            this.$refs.msg.clearTempArr();
                        } else {
                            this.loading = false;
                            this.$Message.error(res.data.msg);
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.$Message.error("新增盘点单失败");
                    });
                }
            });
        },
        closeModal(val) {
            this.$refs.msg.clearTempArr();
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.getCheckerList();
                this.$refs['addForm'].resetFields();
                this.dataList = [];
                this.total = 0;
                this.pages = 1;
                this.pageNum = 1;
                this.pageSize = 5;
                this.$refs.msg.clearTempArr();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
