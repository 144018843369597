<template>
  <div>
    <Modal
      title="查看盘点单"
      v-model="showForm"
      :mask-closable="false"
      width="1200px"
    >
      <div class="divider">盘点信息</div>
      <Form ref="viewForm" label-colon :label-width="90">
        <Row>
          <Col span="12">
            <FormItem label="盘点单号" prop="orderNumber">
              {{ viewForm.orderNumber }}
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="资产数量" prop="quantity">
              {{ viewForm.quantity }}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="开始时间" prop="startTime">
              {{ this.$moment(viewForm.startTime).format('YYYY-MM-DD') }}
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="结束时间" prop="endTime">
              {{ this.$moment(viewForm.endTime).format('YYYY-MM-DD') }}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="盘点单名称" prop="title">
              {{ viewForm.title }}
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="盘点单状态" prop="status">
              <div
                :style="{
                  color:
                    viewForm.status === 0
                      ? '#2d8cf0'
                      : viewForm.status === 1
                      ? '#ff9900'
                      : '',
                }"
              >
                {{
                  viewForm.status === 0
                    ? '未开始'
                    : viewForm.status === 1
                    ? '盘点中'
                    : viewForm.status === 2
                    ? '已结束'
                    : ''
                }}
              </div>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="创建人" prop="createName">
              {{ viewForm.createName }}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="创建时间" prop="createTime">
              {{
                this.$moment(viewForm.createTime).format('YYYY-MM-DD HH:mm:ss')
              }}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="盘点人" prop="checkerName">
              {{ viewForm.checkerName }}
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="备注" prop="memo">
              {{ viewForm.memo }}
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div class="divider">资产信息</div>
      <div class="table">
        <div class="table-list">
          <Table stripe :columns="columns" :data="dataList"> </Table>
        </div>
        <div class="table-footer">
          <Row>
            <Col class="total" span="6"
              >共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col
            >
            <Col class="pagenation" span="18">
              <Page
                :page-size="pageSize"
                :total="total"
                :current="pageNum"
                :page-size-opts="[5, 10, 15, 20]"
                @on-change="changePage"
                @on-page-size-change="changPageSize"
                show-sizer
                show-elevator
              />
            </Col>
          </Row>
        </div>
      </div>
      <div slot="footer">
        <Button type="primary" @click="closeModal(false)">确定</Button>
      </div>
    </Modal>
    <Modal title="查看图片" v-model="visible" footer-hide>
      <img :src="imageUrl" v-if="visible" style="width: 100%" />
    </Modal>
  </div>
</template>

<script>
  import {
    reqGetInfoByInventoryId,
    reqGetAssetPageByInventoryId,
  } from '../../../../api/asset-api'
  import { downloadFileURL } from '../../../../api/system-api'

  export default {
    name: 'viewInventory',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      inventoryId: '',
    },
    data() {
      return {
        showForm: false,
        visible: false,
        viewForm: {
          orderNumber: '',
          title: '',
          checkerName: '',
          startTime: '',
          endTime: '',
          memo: '',
          status: '',
          approvalStatus: '',
          quantity: 0,
          createName: '',
          createTime: '',
        },
        columns: [
          {
            title: '资产编码',
            key: 'encoding',
            tooltip: 'ture',
            width: 170,
          },
          {
            title: '资产名称',
            key: 'assetName',
            tooltip: 'ture',
            minWidth: 150,
          },
          {
            title: '资产分类',
            key: 'classifyName',
            tooltip: 'ture',
            width: 100,
          },
          {
            title: '品牌',
            key: 'brand',
            tooltip: 'ture',
            width: 100,
          },
          {
            title: '型号',
            key: 'model',
            tooltip: 'ture',
            width: 100,
          },
          {
            title: 'SN码',
            key: 'snCode',
            tooltip: 'ture',
            width: 150,
          },
          {
            title: '所在位置',
            key: 'locationName',
            tooltip: 'ture',
            width: 200,
          },
          {
            title: '资产使用人',
            key: 'userName',
            tooltip: 'ture',
            width: 120,
          },
          {
            title: '资产使用部门/车间',
            key: 'userDeptName',
            tooltip: 'ture',
            minWidth: 220,
          },
          {
            title: '资产是否本人使用',
            tooltip: 'ture',
            width: 160,
            render: (h, params) => {
              return h('span', params.row.isSelfUse === 0 ? '否' : '是')
            },
          },
          {
            title: '盘点备注',
            key: 'iaMemo',
            tooltip: 'ture',
            minWidth: 150,
          },
          {
            title: '盘点状态',
            tooltip: 'ture',
            width: 90,
            render: (h, params) => {
              return h('span', [
                h(
                  'span',
                  {
                    style: {
                      color:
                        params.row.iaStatus === 0
                          ? '#2db7f5'
                          : params.row.iaStatus === 1
                          ? '#ff9900'
                          : params.row.iaStatus === 2
                          ? '#ed4014'
                          : '#19be6b',
                    },
                  },
                  params.row.iaStatus === 0
                    ? '未盘'
                    : params.row.iaStatus === 1
                    ? '已盘'
                    : params.row.iaStatus === 2
                    ? '盘亏'
                    : '盘盈'
                ),
              ])
            },
          },
            {
                title: '盘点照片',
                tooltip: 'ture',
                width: 150,
                render: (h, params) => {
                    if (params.row.isSelfUse === 0) {
                        return h('span', '无')
                    } else if (
                        params.row.isSelfUse === 0 ||
                        params.row.iaStatus === 3
                    ) {
                        return h('span', '无')
                    } else {
                        var srcArr = params.row.imgList
                        var imgArr = []
                        srcArr.forEach((item) => {
                            imgArr.push(
                                h('img', {
                                    attrs: {
                                        src: downloadFileURL + item.currentName + '/',
                                        style: 'width: 40px;margin-right:6px',
                                    },
                                    on: {
                                        click: () => {
                                            this.handleView(item.currentName)
                                        },
                                    },
                                })
                            )
                        })
                        return h('div', imgArr)
                    }
                },
            },
        ],
        dataList: [],
        total: 0,
        pages: 1,
        pageNum: 1,
        pageSize: 5,
        downloadFileURL: downloadFileURL,
      }
    },
    watch: {
      value(val) {
        this.showForm = val
      },
      showForm(val) {
        if (val) {
          reqGetInfoByInventoryId({ id: this.inventoryId }).then((res) => {
            this.viewForm = res.data.data[0]
          })
          this.getAssetPage()
        } else {
          // 反之则关闭页面
          this.closeModal(val)
        }
      },
    },
    methods: {
      closeModal(val) {
        this.$emit('input', val)
      },
      //分页点击，切换页面
      changePage(val) {
        this.pageNum = val
        this.getAssetPage()
      },
      //每页显示的数据条数
      changPageSize(numOfRows) {
        //实时获取当前需要显示的条数
        this.pageSize = numOfRows
        this.getAssetPage()
      },
      getAssetPage() {
        let that = this
        that.dataList = []
        let para = {
          pageNum: that.pageNum,
          pageSize: that.pageSize,
          inventoryId: that.inventoryId,
        }
        reqGetAssetPageByInventoryId(para)
          .then((res) => {
            that.total = res.data.data.total
            that.pages = res.data.data.pages
            that.dataList = res.data.data.records
          })
          .catch(() => {
            that.dataList = []
          })
      },
      handleView(url) {
        this.imageUrl = downloadFileURL + url + '/'
        this.visible = true
      },
    },
    mounted() {
      this.getAssetPage()
    },
  }
</script>
