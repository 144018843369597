<template>
    <Modal v-model="showForm" title="选择盘点资产" :mask-closable="false" width="850">
        <div class="filter">
            <Form label-colon inline>
                <FormItem label="关键字" :label-width="60">
                    <Input v-model="keywords" placeholder="资产编码/名称/分类/品牌" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <div>已选中 {{ tempArr.length }} 条</div>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" @on-select="onSelect" @on-select-cancel="onSelectCancel"
                    @on-select-all="onSelectAll" @on-select-all-cancel="onSelectAllCancel">
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize"
                              :total="total"
                              :current="pageNum"
                              :page-size-opts="[10,20,30,40]"
                              @on-change="changePage"
                              @on-page-size-change="changPageSize"
                              show-sizer
                              show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import { reqGetAssetList } from '../../../../api/asset-api';

export default {
    name: "addAsset",
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            showForm: this.value,
            loading: false,
            dataList: [],
            keywords: '',
            total: 0,
            pages: 1,
            pageNum: 1,
            pageSize: 10,
            tempArr: [], // 记录已经勾选的数据
            columns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '资产编码',
                    key: 'encoding',
                    tooltip: 'ture',
                    width: 170
                },
                {
                    title: '资产名称',
                    key: 'name',
                    tooltip: 'ture',
                    minWidth: 120
                },
                {
                    title: '资产分类',
                    key: 'classifyName',
                    tooltip: 'ture',
                    minWidth: 120
                },
                {
                    title: '资产位置',
                    key: 'locationName',
                    tooltip: 'ture',
                    minWidth: 120
                },
                {
                    title: '品牌',
                    key: 'brand',
                    tooltip: 'ture',
                    width: 100
                },
                {
                    title: '型号',
                    key: 'model',
                    tooltip: 'ture',
                    width: 100
                },
            ]
        }
    },
    methods: {
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getAssetList();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getAssetList();
        },
        // 勾选 table 某一项时触发
        onSelect (selection, row) {
            this.tempArr.push(row.id)   // 将该条数据添加到 tempArr 中
            this.dataList.forEach(item => {     // 本页数据中找到该条勾选数据并添加属性 _checked = true
                if (item.id === row.id) {
                item['_checked'] = true
                }
            })
        },
        // 取消勾选 table 某一项时触发
        onSelectCancel (selection, row) {
            this.tempArr.forEach((item, index) => {     // tempArr中找到该条数据并删除
                if (row.id === item) {
                    this.tempArr.splice(index, 1)
                }
            })
        },
        // table 全选时触发
        onSelectAll (selection) {
            selection.forEach(item => { // 将本页全部勾选添加到 tempArr中
                this.tempArr.push(item.id)
            })
            this.tempArr = Array.from(new Set(this.tempArr)) // 去重
        },
        // table取消全选时触发
        onSelectAllCancel (selection) {
            this.dataList.forEach(item => { // tempArr中找到该条数据并删除
                this.tempArr.forEach((e, index) => {
                    if (item.id === e) {
                        this.tempArr.splice(index, 1)
                    }
                })
            })
        },
        // 还原有勾选的数据     params: datas 当前页数据
        handleTableChecked (datas) {
            this.tempArr.forEach(item => { // 判断本页数据状态
                datas.forEach(e => {
                    if (item === e.id) {
                        e['_checked'] = true
                    }
                })
            })
        },
        getAssetList(){
            let that = this;
            let para = {
                pageNum: that.pageNum,
                pageSize: that.pageSize,
                keywords: that.keywords,
            };
            reqGetAssetList(para).then((res) => {
                that.total = res.data.data.total;
                that.pages = res.data.data.pages;
                that.dataList = res.data.data.records;
                that.handleTableChecked(that.dataList); // 恢复有勾选的数据
            }).catch(() => {
                that.dataList = [];
            });
        },
        doSearching(){
            this.pageNum = 1;
            this.getAssetList();
        },
        clearTempArr(){
            this.tempArr = [];
        },
        handleSubmit() {
            this.$emit('getAssetIds', this.tempArr.join(','));
            this.closeModal();
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.getAssetList();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
