<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>资产管理</BreadcrumbItem>
                <BreadcrumbItem>资产使用</BreadcrumbItem>
                <BreadcrumbItem>盘点单</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="keywords" placeholder="单号/盘点单名/盘点人" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="showAddDialog" v-if="permissionButton.add" icon="md-add">新增盘点单</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="action">
                        <template v-if="permissionButton.edit">
                            <a v-if="row.status === 2" disabled>编辑</a>
                            <a @click="editInventory(row,index)" v-else>编辑</a>
                        </template>
                        <Divider type="vertical"/>
                        <a v-if="permissionButton.view" @click="view(row, index)">查看</a>
                        <Divider type="vertical"/>
                        <a @click="delInventory(row,index)" v-if="permissionButton.del">删除</a>
                    </template>
                </Table>
                <viewInventory v-model="viewInventoryShow" :inventoryId="inventoryId" @getInventoryPage="getInventoryPage"></viewInventory>
                <editInventory v-model="editInventoryShow" :inventoryId="inventoryId" @getInventoryPage="getInventoryPage"></editInventory>
                <addInventory v-model="addInventoryShow" @getInventoryPage="getInventoryPage"></addInventory>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize"
                              :total="total"
                              :current="pageNum"
                              :page-size-opts="[15,20,50,100]"
                              @on-change="changePage"
                              @on-page-size-change="changPageSize"
                              show-sizer
                              show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
import permission from '@/common/permission';
import { reqGetInventoryPage, reqDelInventory } from '../../../../api/asset-api';
import viewInventory from '../inventory/view';
import addInventory from '../inventory/add';
import editInventory from '../inventory/edit';

export default {
    name: 'inventoryList',
    components: {
        viewInventory,
        addInventory,
        editInventory,
    },
    data() {
        return {
            filter: {},
            columns: [
                {
                    title: '盘点单号',
                    key: 'orderNumber',
                    tooltip: 'ture',
                    minWidth: 200
                },
                {
                    title: '盘点单名称',
                    key: 'title',
                    tooltip: 'ture',
                    width: 150
                },
                {
                    title: '盘点人',
                    key: 'checkerName',
                    tooltip: 'ture',
                    width: 150
                },
                {
                    title: '开始时间',
                    tooltip: 'ture',
                    minWidth: 150,
                    render: (h, params) => {
                        return h('span', this.$moment(params.row.startTime).format('YYYY-MM-DD'));
                    }
                },
                {
                    title: '结束时间',
                    tooltip: 'ture',
                    minWidth: 150,
                    render: (h, params) => {
                        return h('span', this.$moment(params.row.endTime).format('YYYY-MM-DD'));
                    }
                },
                {
                    title: '资产数量',
                    key: 'quantity',
                    tooltip: 'ture',
                    width: 100
                },
                {
                    title: '盘点单状态',
                    tooltip: 'ture',
                    width: 120,
                    render: (h, params) => {
                        return h('span', [
                            h('span',{
                                style:{
                                    color: params.row.status === 0 ? '#2d8cf0' : (params.row.status === 1 ? '#ff9900' : '')
                                }
                            },params.row.status === 0 ? '未开始' : (params.row.status === 1 ? '盘点中' : (params.row.status === 2 ? '已结束' : '')))
                        ]);
                    }
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 150
                }
            ],
            dataList: [],
            inventoryId: '',
            viewInventoryShow: false,
            editInventoryShow: false,
            addInventoryShow: false,
            keywords: '',
            loading: false,
            permissionButton: {
                add: false,
                edit: false,
                del: false,
                view: false,
            },
            total: 0,
            pages: 1,
            pageNum: 1,
            pageSize: 20
        }
    },
    methods: {
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getInventoryPage();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getInventoryPage();
        },
        //获取分页列表
        doSearching() {
            this.pageNum = 1;
            this.getInventoryPage();
        },
        getInventoryPage(){
            let that = this;
            that.loading = true;
            that.dataList = [];
            let para = {
                pageNum: that.pageNum,
                pageSize: that.pageSize,
                keywords: that.keywords,
            };
            reqGetInventoryPage(para).then((res) => {
                that.loading = false;
                that.total = res.data.data.total;
                that.pages = res.data.data.pages;
                that.dataList = res.data.data.records;
            }).catch(() => {
                that.loading = false;
                that.dataList = [];
            });
        },
        view(row, index){
            this.inventoryId = row.id;
            this.viewInventoryShow = true;
        },
        //显示添加页面
        showAddDialog: function () {
            this.addInventoryShow = true;
        },
        //编辑盘点单
        editInventory(row, index){
            this.inventoryId = row.id;
            this.editInventoryShow = true;
        },
        delInventory(row, index){
            this.$Modal.confirm({
                title: '删除盘点单',
                content: '<p>删除盘点单将清除现有盘点数据，是否继续？</p>',
                width: '240',
                onOk: () => {
                    let para = {inventoryId: row.id};
                    reqDelInventory(para).then(res => {
                        if (res.data.code === 1) {
                            this.$Message.success(res.data.msg);
                            // 删除数据成功同时刷新grid
                            this.getInventoryPage();
                        } else {
                            this.$Message.warning(res.data.msg);
                        }
                    });
                },
            });
        }
    },
    mounted() {
        this.getInventoryPage();
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
    }
}
</script>
